export default class ConsignacaoService {
  constructor(http) {
    this._http = http
  }

  async getListaConsignacoes() {
    let page = 1
    let size = 10
    const { data } = await this._http.get(`/api/consignacao/${page}/${size}`)
    return data
  }

  async getListaConsignacoesPorMatricula(matricudaId) {
    const { data } = await this._http.get(
      `/api/consignacao/matricula/${matricudaId}`,
    )
    return data
  }

  async getListaConsignacoesAtivasDeOutrasConsignatariasPorMatricula(
    matricudaId,
    consignatariaId,
  ) {
    const { data } = await this._http.get(
      `/api/consignacao/outrasConsignatarias/matricula/${matricudaId}/consignataria/${consignatariaId}`,
    )
    return data
  }

  async getListaConsignacoesAtivasPorConsignatariaLogadaEMatricula(
    matriculaId,
    consignatariaId,
  ) {
    const { data } = await this._http.get(
      `/api/consignacao/consignataria/${consignatariaId}/matricula/${matriculaId}`,
    )
    return data
  }

  async getListaConsignacoesRenegociacaoOnline(matriculaId) {
    const { data } = await this._http.get(
      `/api/consignacao/consignataria/matricula/${matriculaId}/renegociacao-online/`,
    )
    return data
  }

  async getListaConsignacoesPorConsignatariaLogadaEMatricula(matriculaId) {
    const { data } = await this._http.get(
      `/api/consignacao/consignacoes/matricula/${matriculaId}`,
    )
    return data
  }

  async getListaConsignacoesRenegociadasPorConsignatariaSelecionadaEMatricula(
    matriculaId,
    consignatariaId,
  ) {
    const { data } = await this._http.get(
      `/api/consignacao/renegociacoes/consignatariaLogada/matricula/${matriculaId}/consignataria/${consignatariaId}`,
    )
    return data
  }

  async getListaConsignacoesRenegociadasPorMatricula(matriculaId) {
    const { data } = await this._http.get(
      `/api/consignacao/renegociacoes/matricula/${matriculaId}`,
    )
    return data
  }

  async getListaConsignacoesPorServidor(servidorId) {
    const { data } = await this._http.get(
      `/api/consignacao/servidor/${servidorId}`,
    )
    return data
  }

  async getConsignacaoById(id) {
    const { data } = await this._http.get(`/api/consignacao/${id}`)
    return data
  }

  async carregarListaStatusConsignacao() {
    const { data } = await this._http.get(
      `/api/consignacao/lista-status-consignacao`,
    )
    return data
  }

  async saveConsignacao(consignacao, consignatariaId) {
    return await this._http.post(
      `/api/consignacao/consignataria/${consignatariaId}`,
      consignacao,
    )
  }

  async atualizar(consignacao) {
    return await this._http.put(`/api/consignacao/atualizar`, consignacao)
  }

  async saveRenegociacao(renegociacao, consignatariaId) {
    if (renegociacao) {
      return await this._http.patch(
        `/api/consignacao/renegociar/consignataria/${consignatariaId}`,
        renegociacao,
      )
    }
  }

  async cancelarConsignacao(consignacaoId, historicoConsignacao) {
    return await this._http.patch(
      `/api/consignacao/cancelar/${consignacaoId}`,
      historicoConsignacao,
    )
  }

  async reajusteMensalidade(consignacaoId, consignatariaId, novaConsignacao) {
    return await this._http.patch(
      `/api/consignacao/reajuste/${consignacaoId}/consignataria/${consignatariaId}`,
      novaConsignacao,
    )
  }

  async cancelarConsignacaoConsignataria(
    consignacaoId,
    consignatariaId,
    historicoConsignacao,
  ) {
    return await this._http.patch(
      `/api/consignacao/cancelar/${consignacaoId}/consignataria/${consignatariaId}`,
      historicoConsignacao,
    )
  }

  async cancelarConsignacaoPorEntidade(
    consignacaoId,
    entidadeId,
    historicoConsignacao,
  ) {
    return await this._http.patch(
      `/api/consignacao/cancelar/${consignacaoId}/entidade/${entidadeId}`,
      historicoConsignacao,
    )
  }

  async getByConsignacaoRenegociadaId(id) {
    const { data } = await this._http.get(
      `/api/consignacao/consignacao-renegociada/${id}`,
    )
    return data
  }
}
